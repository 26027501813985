<template>
  <div class="d-flex flex-row flex-md-column align-center mx-8 mx-md-1 justify-space-between w-full">
    <span class="text-center">
      {{ title }}
    </span>
    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="25"
      :width="3"
    />
    <h3 v-else>
      {{ value }}
    </h3>
  </div>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>
